import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"

const Recipes = ({ data, location }) => {
  const siteTitle = data.site.siteMetadata?.title || `Title`
  const posts = data.allMarkdownRemark.nodes

  if (posts.length === 0) {
    return (
      <Layout location={location} title={siteTitle}>
        <SEO title="Alla podcasts" />
        <p>
          Inga podcasts hittades.
        </p>
      </Layout>
    )
  }

  return (
    <Layout location={location} title={siteTitle}>
      <SEO title="Alla podcasts" />

      <div className="global-wrapper">

        <ol style={{ listStyle: `none` }}>
          {posts.map(post => {
            const title = post.frontmatter.title || post.fields.slug

            return (
              <li key={post.fields.slug}>

                <article
                  className="post-list-item"
                  itemScope
                  itemType="http://schema.org/Article"
                  >
                  <header>
                      <h2 className="remove-margin-bottom remove-padding-bottom">
                      <Link to={post.fields.slug} itemProp="url">
                          <span itemProp="headline">{title}</span>
                      </Link>
                      </h2>
                      <small>{post.frontmatter.date}</small>
                  </header>
                  <section>
                      <iframe title={title} height="122" width="100%" style={{border: 'none'}} scrolling="no" data-name="pb-iframe-player" src={post.frontmatter.podcastLink}></iframe>
                  </section>
                </article>
                
              </li>
            )
          })}
        </ol>
      </div>
    </Layout>
  )
}

export default Recipes

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: {
          categories: {
            in: ["podcast"]
          }
        }
      }, sort: { fields: [frontmatter___date], order: DESC }) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
          podcastLink
        }
      }
    }
  }
`
